<template>
  <div class="mediaReviewReportList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(reports)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(reports)"
        :sortable="{
          allowUnsort: true,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="installationCell"
          slot-scope="{props}"
        >
          <td>
            <router-link
              v-if="props.dataItem.installationId"
              :to="`/installation/${ props.dataItem.installationId }`"
              class="alt-primary-color"
            >
              <span>{{ props.dataItem.installationId }}</span>
            </router-link>
          </td>     
        </template>
        <template
          slot="reviewCell"
          slot-scope="{props}"
        >
          <td>
            <span
              class="alt-primary-color"
              @click="openSessionModal(props.dataItem, true)"
            >{{ props.dataItem.reviewResult ? "Confirmed" : "Rejected" }}</span>
          </td>     
        </template>
        <template
          slot="dateCell"
          slot-scope="{props}"
        >
          <td>{{ dateTime_dateTimeSeconds(kgm_getNestedValue(props.field, props.dataItem)) }}</td>
        </template>
        <template
          slot="lastDateCell"
          slot-scope="{props}"
        >
          <td
            class="alt-primary-color"
            @click="openSessionModal(props.dataItem, false)"
          >
            {{ dateTime_dateTimeSeconds(kgm_getNestedValue(props.field, props.dataItem)) }}
          </td>
        </template>

        <div
          slot="idFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="idInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter an id"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('idInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="spotFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="spotInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a spot"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('spotInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="installationFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="installationInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a installation"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('installationInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="resultFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="resultInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a result"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('resultInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="dateFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="dateInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a date"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('dateInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="lastDateFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="lastDateInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a date"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('lastDateInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="countFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="countInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a number"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('countInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
      </Grid>
      <SweetModal
        ref="sessions"
        blocking
        class="overflowHidden"
      >
        <LoadingPlaceholder v-if="modalLoading" />
        <template v-else-if="session">
          <div class="row">
            <MediaReviewPhotoSessionOverview
              v-if="session.firstItem && getRunType(session.firstItem.type) == 'Photo'"
              :run="session.firstItem"
            />
            <MediaReviewVideoSessionOverview
              v-else-if="session.firstItem && getRunType(session.firstItem.type) == 'Video'"
              :run="session.firstItem"
              :is-playing="true"
            />
            <MediaReviewPhotoSessionOverview
              v-else
              :run="session.firstItem"
            />
            <MediaReviewPhotoSessionOverview
              v-if="session.secondItem && getRunType(session.secondItem.type) == 'Photo'"
              :run="session.secondItem"
            />
            <MediaReviewVideoSessionOverview
              v-else-if="session.secondItem && getRunType(session.secondItem.type) == 'Video'"
              :run="session.secondItem"
              :is-playing="true"
            />
            <MediaReviewPhotoSessionOverview
              v-else
              :run="session.secondItem"
            />
            <MediaReviewPhotoSessionOverview
              v-if="session.thirdItem && getRunType(session.thirdItem.type) == 'Photo'"
              :run="session.thirdItem"
            />
            <MediaReviewVideoSessionOverview
              v-else-if="session.thirdItem && getRunType(session.thirdItem.type) == 'Video'"
              :run="session.thirdItem"
              :is-playing="true"
            />
            <MediaReviewPhotoSessionOverview
              v-else
              :run="session.thirdItem"
            />        
          </div>
        </template>
        <button
          slot="button"
          class="btn btn-secondary float-right mb-3"
          @click="$refs.sessions.close()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="times"
          />{{ $t('cancel') }}
        </button>
        <div class="clearfix" />
      </SweetModal>
    </template>
  </div>
</template>

<script>
import { kendoGridMixin } from "@/mixins/kendoGridMixin.js";
import { dateTimeMixin } from "@/mixins/dateTimeMixin.js";
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: "MediaReviewReportList",
   components: {
    SweetModal,
    MediaReviewPhotoSessionOverview: () => import("@/components/MediaReview/MediaReviewPhotoSessionOverview.vue"),
    MediaReviewVideoSessionOverview: () => import("@/components/MediaReview/MediaReviewVideoSessionOverview.vue")
  },
  mixins: [kendoGridMixin, dateTimeMixin],
  data () {
    return {
      loading: true,
      reports: null,
      session: null,
      modalLoading: false,
      kgm_columns: [
        {
          field: "spotId",
          filterable: true,
          filter: "text",
          title: "Id",
          filterCell: 'idFilter'
        },
        {
          field: "spotName",
          filterable: true,
          filter: "text",
          title: "Spot Name",
          filterCell: 'spotFilter'
        },
        {
          field: "installation",
          filterable: true,
          filter: "text",
          title: "Installation",
          filterCell: 'installationFilter',
          cell: 'installationCell'
        },
        {
          field: "reviewResult",
          filterable: true,
          filter: "text",
          title: "Last Review Result",
          filterCell: 'resultFilter',
          cell: 'reviewCell'
        },
        {
          field: "reviewDate",
          filterable: true,
          filter: "text",
          title: "Last Review Date",
          filterCell: 'dateFilter',
          cell: 'dateCell'
        },
        {
          field: "lastRejectedDate",
          filterable: true,
          filter: "text",
          title: "Last Rejected Review Date",
          filterCell: 'lastDateFilter',
          cell: 'lastDateCell'
        },
        {
          field: "rejectedCount",
          filterable: true,
          filter: "text",
          title: "Number of Rejected Reviews in last 10 Reviews",
          filterCell: 'countFilter'
        }
      ]
    };
  },
  created () {
    this.getData();
  },
  methods: {
    getRunType (val) {
      var videoType = ["Race", "Carving", "Cross", "MixRace", "Slalom", "GiantSlalom", "Freeride"];
      var photoType = ["Speed", "Photostart"];
      if(videoType.includes(val)) {
        return "Video";
      }
      if(photoType.includes(val)) {
        return "Photo";
      }
      return "";
    },
    //#region API-calls
    openSessionModal (report, byObj) {
      if(byObj) {
        this.getSessionByObj(report);
      }
      else {
        this.getSessionById(report.lastRejectedId);
      }
      this.$refs.sessions.open();
    },
    getData () {
      this.loading = true;
      this.axios
        .get(`/Media/GetMediaReviewReports`)
        .then(response => {
          this.reports = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSessionByObj (obj) {
      this.modalLoading = true;
      this.axios
        .post(`/Media/GetMediaReviewReportSessionsByObj`, obj)
        .then(response => {
           if(response && response.data != null) {
            this.session = response.data;
          }
        })
        .finally(() => {
          this.modalLoading = false;
        });
    },
    getSessionById (id) {
      this.modalLoading = true;
      this.axios
        .get(`/Media/GetMediaReviewReportSessionsById?id=${id}`)
        .then(response => {
           if(response && response.data != null) {
            this.session = response.data;
          }
        })
        .finally(() => {
          this.modalLoading = false;
        });
    }
  }
};
</script>
<style>
.mediaReviewReportList .k-grid table tr:hover td {
  background: rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>